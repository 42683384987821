#recommendationContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#recommendationSearchbar {
  position: relative;
  background-color: white;
  border-radius: 20px;
  padding: 1em 1em;
  width: 60%;
  height: 10vh;
}

#recommendationSearchbar .textbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#recommendationSearchbar input {
  width: 90%;
  height: 100%;
  border: none;
}

#recommendationSearchbar input:focus {
  outline: none;
}

#recommendationSearchbar .icon {
  cursor: pointer;
  color: gray;
}
