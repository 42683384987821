body {
  margin: 0;
  padding: 0;
  color: white;
  box-sizing: border-box;
  background-color: #17171d;
}

#container {
  min-height: max-content;
  max-width: min-content;
  background-color: transparent;
}

#img {
  max-height: 40vh;
  min-width: 50vh;
}

#inside {
  background-color: #03c4a1;
}
