#logo {
  font-size: xx-large;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.navContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 2em 0.1em;
}

#searchbar {
  position: relative;
  background-color: white;
  border-radius: 20px;
  padding: 1em 1em;
  width: 60%;
  height: 100%;
}

.textbox {
  display: flex;
  justify-content: space-between;
}

input {
  width: 90%;
  border: none;
}

input:focus {
  outline: none;
}

.icon {
  cursor: pointer;
  color: gray;
}

#recommendBtn {
  border: 2px solid rgb(34, 253, 188);
  background-color: transparent;
  border-radius: 5px;
  padding: 20px 30px;
  color: white;
  cursor: pointer;
}

#recommendBtn:hover {
  background-color: rgb(34, 253, 188);
  color: black;
}
